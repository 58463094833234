import React from "react"
import { DownOutlined } from "@ant-design/icons"
import QueueAnim from "rc-queue-anim"
import TweenOne from "rc-tween-one"
import PageVisible from "../PageVisible"

let logo2 = "./images/logo2.png"
let Tc
let scene
export default class Banner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageOnTheFront: true,
      show3D: false,
      renderWidth: 500,
      renderHeight: 500,
    }
  }

  componentDidMount() {
    let _this = this
    window.addEventListener("resize", this.onWindowResize, false)
    import("../ThreeCanvas").then(({ ThreeCanvas }) => {
      Tc = ThreeCanvas
      import("../../Scenes/TopScene").then(({ Scene }) => {
        scene = Scene
        _this.setState({
          show3D: true,
          renderWidth: document.body.clientWidth,
          renderHeight: document.body.clientHeight,
        })
      })
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize, false)
  }

  onWindowResize = ev => {
    this.setState({
      renderWidth: document.body.clientWidth,
      renderHeight: document.body.clientHeight,
    })
  }

  render() {
    const { renderWidth, renderHeight, pageOnTheFront, show3D } = this.state

    return (
      <div className="banner">
        {show3D && (
          <>
            <Tc
              width={renderWidth}
              height={renderHeight}
              devicePixelRatio={window.devicePixelRatio}
              render={(renderer, time) => {
                return pageOnTheFront
              }}
              scene={scene}
            />
            <PageVisible
              onShow={() => {
                console.log("show")
                this.setState({ pageOnTheFront: true })
              }}
              onHide={() => {
                console.log("hide")
                this.setState({ pageOnTheFront: false })
              }}
            />
          </>
        )}
        <QueueAnim
          key="QueueAnim"
          type={["bottom", "top"]}
          delay={200}
          className="banner-text-wrapper"
        >
          <div key="title" className="banner-title">
            <img src={logo2} width="100%" alt="img" />
          </div>
          <div key="content" className="banner-content">
            物联是未来，交互靠什么？
          </div>
        </QueueAnim>
        <TweenOne
          animation={{
            y: "-=20",
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner-icon"
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
        {this.props.children}
      </div>
    )
  }
}
