/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import TweenOne from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
import { Row, Col } from "antd";

// import logo2 from "../img/logo2.png";
let logo2 = "./images/logo2.png";;

export default class Footer extends React.Component {
  render() {
    return (
      <div className="home-page-wrapper footer">
        <OverPack className="footer" playScale={0.2}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
            component={Row}
            gutter={0}
            className="home-page"
          >
            <Col key="0" className="block" xs={24} md={6}>
              <h2 className="logo">
                <img src={logo2} width="100%" alt="img" />
              </h2>
              <div className="slogan">
                <h2>热爱，可抵岁月漫长</h2>
              </div>
            </Col>
            <Col key="1" className="block" xs={24} md={6}>
              <h2>关于</h2>
              <a href="#">FAQ</a>
              <a href="#">联系我</a>
            </Col>
            <Col key="2" className="block" xs={24} md={6}>
              <h2>资源</h2>
              <a href="#">我还在想一</a>
              <a href="#">我还在想二</a>
            </Col>
            <Col key="3" className="block" xs={24} md={6}>
              <h2>说明</h2>
              <a href="#">设计灵感：Lusion</a>
              <a href="#">主要技术：ThreeJS AntD</a>
            </Col>
          </QueueAnim>
          <TweenOne
            animation={{ y: "+=30", opacity: 0, type: "from" }}
            key="copyright"
            className="copyright-wrapper"
          >
            <div className="home-page">
              <div className="copyright">
                <span>
                  <a
                    target="_blank"
                    href="http://www.beian.miit.gov.cn/"
                    rel="noreferrer"
                  >
                    沪ICP备19019923号
                  </a>
                </span>
              </div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}
