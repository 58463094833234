import React from "react"
import { Row, Col } from "antd"
import { TweenOneGroup } from "rc-tween-one"
import OverPack from "rc-scroll-anim/lib/ScrollOverPack"

// import scene_car from "../../images/scene/car.png"
// import scene_beauty from "../../images/scene/beauty.png"
// import scene_geo from "../../images/scene/geo.png"
// import scene_led from "../../images/scene/led.png"
// import scene_scan from "../../images/scene/scan.png"
// import scene_heatmap from "../../images/scene/heatmap.png"
// import scene_hologram from "../../images/scene/hologram.png"
// import scene_stencilDepth from "../../images/scene/stencilDepth.png"
// import scene_dissolve from "../../images/scene/dissolve.png"

let data = [
  {
    title: "webgl试妆初探",
    href: "https://www.easonyi.com/demo/webgl/index.html?scene=beautyScene",
    cover: "./images/scene/beauty.png",
  },
  {
    title: "车辆简单控制",
    href: "https://www.easonyi.com/demo/webgl/index.html?scene=carScene",
    cover: "./images/scene/car.png",
  },
  {
    title: "GeoJson中国地图",
    href: "https://www.easonyi.com/demo/webgl/index.html?scene=geoScene",
    cover: "./images/scene/geo.png",
  },
  {
    title: "消融效果",
    href: "https://www.easonyi.com/demo/webgl/index.html?scene=dissolveScene",
    cover: "./images/scene/dissolve.png",
  },
  {
    title: "后处理场景扫描效果",
    href: "https://www.easonyi.com/demo/webgl/index.html?scene=scanScene",
    cover: "./images/scene/scan.png",
  },
  {
    title: "热力图三维化",
    href: "https://www.easonyi.com/demo/webgl/index.html?scene=heatmapScene",
    cover: "./images/scene/heatmap.png",
  },
  {
    title: "全息材质node",
    href: "https://www.easonyi.com/demo/webgl/index.html?scene=hologramScene",
    cover: "./images/scene/hologram.png",
  },
  {
    title: "穿墙透视效果",
    href: "https://www.easonyi.com/demo/webgl/index.html?scene=stencilDepthScene",
    cover: "./images/scene/stencilDepth.png",
  },
]

export default class Project extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="home-page-wrapper project">
        <div className="home-page project-wrapper">
          <div key="title" className="title-wrapper">
            <h1 className="title-h1">Eason的随手写写</h1>
            <div className="title-content">有空就写，没空拉倒</div>
          </div>
          <OverPack className="content-template" playScale={0.3}>
            <TweenOneGroup
              component={Row}
              key="ul"
              enter={{
                y: "+=30",
                opacity: 0,
                type: "from",
                ease: "easeInOutQuad",
              }}
              leave={{ y: "+=30", opacity: 0, ease: "easeInOutQuad" }}
              gutter={16}
              className="project-img-wrapper"
            >
              {data.map((item, i) => {
                return (
                  <Col key={i} className="block" md={6} xs={24}>
                    <a
                      className="project-block-content"
                      href={item.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <img src={item.cover} height="100%" alt="img" />
                      </span>
                      <p>{item.title}</p>
                    </a>
                  </Col>
                )
              })}
            </TweenOneGroup>
          </OverPack>
        </div>
      </div>
    )
  }
}
