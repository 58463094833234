import React from "react"
import "./index.less"
import Home from "../components/Home"
import Seo from "../components/seo"

export default function PageIndex() {
  return (
    <>
      <Seo title="交互未来" />
      <Home></Home>
    </>
  )
}
