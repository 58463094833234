import React from "react"
import TweenOne from "rc-tween-one"
import { Menu } from "antd"
// import logo from "../img/logo2.png";
let logo = "./images/logo2.png"
const { SubMenu } = Menu

let mockData = [
  {
    title: "博客",
    sub: [
      {
        title: "CSDN",
        link: "https://blog.csdn.net/yichen454",
      },
    ],
  },
  {
    title: "工具",
    sub: [
      {
        title: "三角剖分",
        link: "https://www.easonyi.com/demo/webgl/index.html?scene=faceMaskUtil",
      },
      {
        title: "全景转六面",
        link: "https://www.easonyi.com/demo/panorama-to-cubemap/",
      },
    ],
  },
]

export default class Nav extends React.Component {
  render() {
    return (
      <TweenOne
        className="header home-page-wrapper"
        animation={{ opacity: 0, type: "from" }}
      >
        <div className="home-page open">
          <TweenOne
            className="header-logo"
            animation={{ x: -50, type: "from", ease: "easeOutQuad" }}
          >
            <img width="50%" src={logo} alt="img" />
          </TweenOne>

          <TweenOne
            className="header-menu"
            animation={{ x: 50, type: "from", ease: "easeOutQuad" }}
          >
            <Menu mode={"horizontal"} selectedKeys={[""]} theme="dark">
              {mockData.map((item, key) => {
                return (
                  <SubMenu key={item.title} title={item.title}>
                    {item.sub.map((object, index) => {
                      return (
                        <Menu.Item key={`${item.title}:${index}`}>
                          <a
                            href={object.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {object.title}
                          </a>
                        </Menu.Item>
                      )
                    })}
                  </SubMenu>
                )
              })}
            </Menu>
          </TweenOne>
        </div>
      </TweenOne>
    )
  }
}
