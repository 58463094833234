import React from "react"
import "./less/index.less"
import Nav from "./nav"
import Banner from "./banner";
import Project from "./project"
import Footer from "./footer"

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageOnTheFront: true,
      renderWidth: 1920,
      renderHeight: 1080,
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div style={{ width: "100%" }}>
        <Banner>
          <Nav />
        </Banner>

        <Project />

        <Footer />
      </div>
    )
  }
}
